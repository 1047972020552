<script lang="ts">
  import Footer from 'src/components/Footer.svelte'
  import { setCurrentScreen } from 'src/components/analytics/Analytics.svelte'
  import RecruitmentRequirements from 'src/components/recruitment/RecruitmentRequirements.svelte'
  import Header from 'src/components/specificTrade/Header.svelte'
  import { onMount } from 'svelte'

  setCurrentScreen('recruitment')
  onMount(() => window.scrollTo(0, 0))
</script>

<Header />
<RecruitmentRequirements />
<Footer />
