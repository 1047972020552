<script>
</script>

<div class="bg" />

<style lang="postcss">
  .bg {
    @apply h-full
      w-full;
    background: linear-gradient(85.78deg, #7ce6d7 -2.18%, #f7dc69 105.33%);
  }
</style>
