<script>
  import { CONTACT_URL } from 'src/constant'
</script>

<div class="page">
  <section>
    <h1>特定商取引法に基づく表示</h1>
    <h2>代表者</h2>
    <p>諸岡 明</p>
    <h2>所在地</h2>
    <p>
      神奈川県川崎市中原区中丸子597-7<br />オモテ・ワタナベ 106号
    </p>
    <h2>販売価格</h2>
    <p>消費税および、販売手数料を含めた金額で表示されています。</p>
    <h2>お支払い方法</h2>
    <p>
      各種クレジットカード（Visa、Mastercard、American Express、Discover、Diners Club、JCB）でのお支払いが可能です。
    </p>
    <h2>お支払い時期</h2>
    <p>プラン開始の月の初日に決済します。</p>
    <h2>返金・キャンセル等</h2>
    <p>
      商品の特性上、原則として返金・キャンセルは承っておりませんが、以下の場合においてのみ返金・キャンセルが可能です。
    </p>
    <ul>
      <li>システムの不備により、お客さまの意図しない決済が生じたとき</li>
      <li>コーチ都合により、お客さまがサービスを受けられない状況が生じたとき</li>
    </ul>
    <p>決済手続き完了より７日以内に、下記のお問い合わせ窓口までご連絡ください。</p>
    <h2>お問い合わせ</h2>
    <a href={CONTACT_URL} rel="noreferrer noopener" target="_blank">お問い合わせフォームリンク</a>
  </section>
</div>

<style>
  .page {
    @apply bg-accent1;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
  }
  section {
    @apply container
      px-6
      py-8
      text-appBlack;
  }
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-lg font-bold mt-9;
  }
  p {
    @apply text-sm mt-2;
  }
  a {
    @apply underline;
  }
</style>
