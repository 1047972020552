<script lang="ts">
  export let disabled = false
</script>

<button {disabled} on:click>
  <img src="/images/line_icon.png" class="mr-2" alt="LINEから申し込む" />
  <p>LINEから申し込む</p>
</button>

<style>
  button {
    background-color: #06c755;

    @apply rounded-full
      w-full
      flex
      items-center
      justify-center
      py-3
      px-5;
  }

  button:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    filter: grayscale(100%);
  }

  p {
    @apply text-lg
      inline-block
      text-white;
  }

  button img {
    height: 34px;
  }

  @screen laptop {
    button {
      @apply py-5
        px-14;
    }

    p {
      @apply text-2xl;
    }
  }

  @screen desktop {
    button {
      @apply py-5
        px-14;
    }

    p {
      @apply text-2xl;
    }
  }
</style>
