<script lang="ts" context="module">
  import { getAnalytics, logEvent as logEventInternal } from 'firebase/analytics'
  import { initializeApp } from 'firebase/app'

  const firebaseConfig = {
    apiKey: 'AIzaSyD-RoykK3M3U2-J8vc2glPN5-8dt3WOgD8',
    authDomain: 'tech4you-3b7aa.firebaseapp.com',
    projectId: 'tech4you-3b7aa',
    storageBucket: 'tech4you-3b7aa.appspot.com',
    messagingSenderId: '600606275753',
    appId: '1:600606275753:web:5aee64654500ebe08651b8',
    measurementId: 'G-SY491QHSG4',
  }
  const app = initializeApp(firebaseConfig)

  type EventParams = {
    [key: string]: any
  }

  export function createApplyRootEvent(eventId: string): EventParams {
    return {
      content_type: 'apply_root',
      eventId,
    }
  }

  export function createApplyTrialEvent(eventId: string): EventParams {
    return {
      content_type: 'apply_trial',
      eventId,
    }
  }

  export function createShowCoachEvent(eventId: string): EventParams {
    return {
      content_type: 'show_coach',
      eventId,
    }
  }

  export function createShowRecruitmentEvent(eventId: string): EventParams {
    return {
      content_type: 'show_recruitment',
      eventId,
    }
  }

  export const analytics = getAnalytics(app)
  export const logEvent = (eventName: string, params: EventParams) => {
    logEventInternal(analytics, eventName, params)
  }

  export const setCurrentScreen = (screenName: string) => {
    logEvent('screen_view', { screen_name: screenName })
  }

  export const clickEvent = (params: EventParams) => {
    logEvent('select_content', params)
  }
</script>
