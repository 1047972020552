<script>
  import { bind } from 'src/elements/Bind.svelte'
  import * as svelte from 'svelte'
  import { fade } from 'svelte/transition'
  import { DefaultContextKey } from './Constant.svelte'

  const baseSetContext = svelte.setContext
  let modalComponent = null
  let bgElement = null

  const open = (NewComponent, newProps = {}) => {
    modalComponent = bind(NewComponent, newProps)
    disableScroll()
  }
  const close = () => {
    if (!modalComponent) return
    modalComponent = null
    enableScroll()
  }
  baseSetContext(DefaultContextKey, { open, close })

  let scrollY = 0
  let prevBodyPosition = ''
  let prevBodyOverflow = ''
  let prevBodyWidth = ''
  const disableScroll = () => {
    scrollY = window.scrollY
    prevBodyPosition = document.body.style.position
    prevBodyOverflow = document.body.style.overflow
    prevBodyWidth = document.body.style.width
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}px`
    document.body.style.overflow = 'hidden'
    document.body.style.width = '100%'
  }

  const enableScroll = () => {
    document.body.style.position = prevBodyPosition || ''
    document.body.style.top = ''
    document.body.style.overflow = prevBodyOverflow || ''
    document.body.style.width = prevBodyWidth || ''
    window.scrollTo(0, scrollY)
  }

  let outerClickTarget = null
  const handleOuterMousedown = (event) => {
    if (event.target === bgElement) {
      outerClickTarget = event.target
    }
  }

  const handleOuterMouseup = (event) => {
    if (event.target === outerClickTarget) {
      event.preventDefault()
      close()
    }
    outerClickTarget = null
  }

  const handleClickClose = () => {
    close()
  }
</script>

{#if modalComponent}
  <div
    class="bg"
    bind:this={bgElement}
    on:mousedown={handleOuterMousedown}
    on:mouseup={handleOuterMouseup}
    transition:fade={{ duration: 250 }}
  >
    <div class="window">
      <button class="close-button" on:click={handleClickClose}>
        <img src="/images/close.svg" alt="close" />
      </button>
      <div class="content">
        <svelte:component this={modalComponent} />
      </div>
    </div>
  </div>
{/if}
<slot />

<style>
  .bg {
    @apply fixed
      top-0
      left-0
      z-50
      w-screen
      h-screen
      flex
      flex-col
      justify-center
      items-center;

    background: rgb(0 0 0 / 66%);
  }

  .window {
    @apply relative
      p-8
      bg-white
      rounded-lg;

    max-width: 85%;
    max-height: 65%;
  }

  .close-button {
    @apply absolute
      top-4
      right-4
      cursor-pointer;
  }

  .content {
    @apply w-full h-full overflow-y-scroll;
  }

  @screen laptop {
    .window {
      @apply p-16;

      max-width: 80%;
      max-height: 95%;
    }

    .close-button {
      @apply top-6
        right-6;
    }

    .close-button img {
      @apply w-8
        h-8;
    }
  }
</style>
