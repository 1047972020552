<script lang="ts">
  import clsx from 'clsx'

  export let text = 'Undefined title'
  export let className = ''
  export let minWidth = 320
  export let colorClass = 'bg-appYellow'
  export let disabled = false
</script>

<button {disabled} style="min-width: {minWidth}px;" class={clsx(className, colorClass)} on:click>
  <p>
    {text}
  </p>
</button>

<style>
  button {
    @apply rounded-full
      py-3
      px-5
      font-bold;
  }

  button:disabled {
    @apply opacity-50
      cursor-not-allowed;
  }

  p {
    @apply text-base
      inline-block;
  }

  .decoration {
    display: inline;
    background: linear-gradient(transparent 50%, rgb(145 198 190 / 30%) 50%);
  }

  @screen laptop {
    button {
      @apply py-5
        px-14;
    }

    p {
      @apply text-xl;
    }
  }

  @screen desktop {
    button {
      @apply py-5
        px-14;
    }

    p {
      @apply text-xl;
    }
  }
</style>
