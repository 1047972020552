<script lang="ts">
  import { FREE_ADVICE_URL } from 'src/constant'

  import Link from 'src/elements/Link.svelte'
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import { clickEvent, createApplyRootEvent } from '../analytics/Analytics.svelte'
  import LineButton from './LineButton.svelte'

  const onClickForm = () => {
    const callback = function () {
      clickEvent(createApplyRootEvent('form'))
      window.open(FREE_ADVICE_URL, '_blank', 'noreferrer')
    }
    // eslint-disable-next-line no-undef
    gtag('event', 'conversion', {
      send_to: 'AW-317690068/RAcLCJCjxdIZENShvpcB',
      event_callback: callback,
    })
  }

  const onClickLine = () => {
    const callback = function () {
      clickEvent(createApplyRootEvent('line'))
      window.open('https://lin.ee/Q8eX8MH')
    }
    // eslint-disable-next-line no-undef
    gtag('event', 'conversion', {
      send_to: 'AW-317690068/cEjMCI77ytIZENShvpcB',
      event_callback: callback,
    })
  }

  export let maintenance = false
</script>

<div class="popup">
  <div>
    <div><LineButton disabled={maintenance} on:click={onClickLine} /></div>

    <Body className="mt-2" text="LINE公式アカウントをともだち追加してお申し込みください" />
    <div class="mt-6">
      <RoundButton
        disabled={maintenance}
        text="フォームから申し込む"
        minWidth={100}
        className="w-full"
        on:click={onClickForm}
      />
    </div>
    {#if maintenance}
      <Body className="text-red-500 font-bold" text="申し訳ございません。ただいま満員のため受付を中止しています。" />

      <Link
        external={true}
        href="https://docs.google.com/forms/d/e/1FAIpQLSdqJ57uxHe4HQI0zWa4JE7kAoRDcMcrL9lrcoKCb4C2aBDeZQ/viewform?usp=sf_link"
        text="空きが出た場合に無料相談の案内を受取たい方"
        className="font-bold underline text-center mt-2"
      />
    {/if}
  </div>
</div>

<style>
  .popup {
    @apply flex 
      w-full
      flex-col
      space-y-6
      pt-3;
  }
</style>
