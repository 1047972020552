<script>
  import Background from 'src/components/recruitment/Background.svelte'

  import { RECRUITMENT_URL } from 'src/constant'
  import RoundButton from 'src/elements/RoundButton.svelte'
  import Body from 'src/theme/text/Body.svelte'
  import Title from 'src/theme/text/Title.svelte'

  import { clickEvent, createShowRecruitmentEvent } from '../analytics/Analytics.svelte'

  const onClickForm = () => {
    clickEvent(createShowRecruitmentEvent('form'))
    window.open(RECRUITMENT_URL, '_blank', 'noreferrer')
  }
</script>

<div class="page">
  <Background />
  <section>
    <div class="card">
      <div class="title">
        <Title text="コーチをやってみたい方を募集しています" className="font-bold text-center" />
      </div>
      <div class="body-1">
        <Body
          text="TECH FOR YOUでは、「エンジニア向けのコーチングをやってみたい」「コーチングの副業に興味がある」という方を募集しています。もしご興味がありましたら、ぜひ一度お気軽に面談にお申し込みください。 コーチング自体は初めてでも構いません。"
        />
        <Body text="※ 現在は、専門での採用を行っておりません。副業で活動できる方が前提となります。" className="mt-4" />
      </div>

      <div class="body-2">
        <Body text="■ 募集要項" />
        <Body text="・エンジニア経験(3年以上経験があると望ましい)" />
        <Body text="・他のエンジニアの成長に情熱を持っている" />
        <Body text="・コーチングの副業に興味がある人" />
        <Body text="・etc" />
      </div>

      <Body className="text-center" text="コーチングに少しでも興味がありましたら、お気軽お問い合わせください。" />
      <Body className="text-center mb-6" text="一緒に活動していける方をお待ちしております。" />

      <div class="button">
        <RoundButton text="お問い合わせフォーム" on:click={onClickForm} />
      </div>
    </div>
  </section>
</div>

<style lang="postcss">
  section {
    @apply container
      px-6
      py-8
      text-appBlack;
  }

  .page {
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    background: linear-gradient(85.78deg, #7ce6d7 -2.18%, #f7dc69 105.33%);
    @apply flex 
      flex-col 
      items-center 
      justify-center;
  }

  .card {
    @apply flex 
      flex-col 
      items-center 
      py-12 
      px-6;
    width: 100%;
    background: white;
  }

  .title {
    @apply mb-8;
  }
  .body-1 {
    @apply mb-8;
  }
  .body-2 {
    @apply mb-4 self-start;
  }
  @screen laptop {
    .card {
      @apply py-24 px-16;
    }
    .title {
      @apply mb-16;
    }
    .body-2 {
      @apply mb-16;
    }
  }
  @screen desktop {
    .card {
      @apply py-24 px-32;
    }
    .title {
      @apply mb-24;
    }
    .body-2 {
      @apply mb-24;
    }
  }
</style>
