<script lang="ts">
  import Body from 'src/theme/text/Body.svelte'

  export let href = '#'
  export let text = ''
  export let className = ''
  export let external = false
</script>

{#if external}
  <a {href} rel="noreferrer noopener" target="_blank" on:click>
    <Body {text} {className} />
  </a>
{:else}
  <a {href} on:click>
    <Body {text} {className} />
  </a>
{/if}
