<script lang="ts">
  export let text: string
  export let className = ''
</script>

<p class={className}>{text}</p>

<style>
  p {
    @apply text-2xl inline-block;
  }
  @screen laptop {
    p {
      @apply text-3xl;
    }
  }
  @screen desktop {
    p {
      @apply text-4xl;
    }
  }
</style>
