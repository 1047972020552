<script>
  import { clickEvent, createShowRecruitmentEvent } from 'src/components/analytics/Analytics.svelte'
  import { CONTACT_URL } from 'src/constant'

  import Link from 'src/elements/Link.svelte'
  import Logo from './Logo.svelte'
</script>

<footer>
  <div class="inner">
    <div>
      <Logo imgClass="h-7" />
    </div>
    <div>
      <Link external={true} href={CONTACT_URL} text="お問い合わせ" className="font-bold underline" />
    </div>
    <div>
      <Link href="/#/specified" text="特定商取引法に基づく表示" className="font-bold underline" />
    </div>
    <div>
      <Link
        href="/#/recruitment"
        text="コーチの募集"
        className="font-bold underline"
        on:click={() => {
          clickEvent(createShowRecruitmentEvent('footer'))
        }}
      />
    </div>
  </div>
</footer>

<style>
  footer {
    @apply p-6 bg-appBlue-500;
    min-height: var(--footer-height);
  }
  .inner {
    @apply container 
      mx-auto
      flex
      flex-col
      px-6
      space-y-3;
  }
  @screen laptop {
    .inner {
      @apply px-2 
        flex-row 
        space-y-0 
        space-x-6
        justify-center
        items-center;
    }
  }
</style>
