<script lang="ts">
  import Logo from '../Logo.svelte'
</script>

<header>
  <div class="inner">
    <div class="logo">
      <Logo imgClass="h-9" />
    </div>
  </div>
</header>

<style>
  header {
    height: var(--header-height);
  }
  .inner {
    @apply container 
      h-full
      flex
      items-center
      justify-between
      px-6;
  }
  .logo {
    @apply flex;
  }
</style>
