<script lang="ts">
  import Footer from 'src/components/Footer.svelte'
  import { setCurrentScreen } from 'src/components/analytics/Analytics.svelte'
  import Header from 'src/components/specificTrade/Header.svelte'
  import SpecificTrade from 'src/components/specificTrade/SpecificTrade.svelte'
  import { onMount } from 'svelte'

  setCurrentScreen('specified')
  onMount(() => window.scrollTo(0, 0))
</script>

<Header />
<SpecificTrade />
<Footer />
